/* Fonst Imports*/
@font-face {
  font-family: "Andika";
  src: url("../fonts/Andika-Regular.ttf");
}
@font-face {
  font-family: "Andika";
  src: url("../fonts/Andika-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "rajdhani-medium";
  src: url("../fonts/rajdhani-medium.ttf");
}
@font-face {
  font-family: "rajdhani-semibold";
  src: url("../fonts/rajdhani-semibold.ttf");
}
/* Notebook Cell Css */
.notebook-container {
  background-color: white;
  flex-direction: column;
  box-sizing: border-box;
  top: 8vh;
  font-family: "rajdhani-medium";
  display: flex;
  height: 92vh;
  position: fixed;
  width: 92vw;
  left: 8vw;
}
.notebook-title-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "rajdhani-medium";
  padding: 0 1vw;
  z-index: 1;
  background-color: #e2e7ea !important;
  position: sticky;
}
.exercisenotebook-container,
.assessmentnotebook-container {
  overflow: auto;
}
.exercisenotebook-container .cell-container,
.assessmentnotebook-container .cell-container {
  overflow: hidden;
}
.notebook-exercise-levels,
.notebook-assessment-levels {
  border: 1px solid #00868d;
  margin: 1vw;
  overflow: hidden;
}
.notebook-exercise-levels .text-cell,
.notebook-assessment-levels .text-cell {
  margin: 2vh 1.5vw;
}
.notebook-exercise-levels #panel1-header,
.notebook-assessment-levels #panel1-header {
  background-color: #00868d;
  color: white;
  font-size: 1.5vw;
  min-height: unset !important;
}
.notebook-drawer-box {
  left: 0;
}
.python-cell-container #python-instruction {
  padding: 0 15px 15px 15px !important;
}
.python-cell-container #python-instruction p {
  font-size: 1.6vw !important;
}
.python-cell-container #python-instruction h2 {
  font-size: 1.9vw !important;
}
.graphic-mode-python-container .python-instruction-container,
.graphic-mode-python-container .python-code-editor-container {
  width: calc((100% - 400px) / 2) !important;
}
/* Text Cell */
.cell-container .text-cell {
  font-size: 1.8vw;
  font-family: "rajdhani-medium";
}
.cell-container .text-cell p {
  margin: 0;
}
.cell-container .text-cell img {
  display: block;
  margin: auto;
  max-width: 90%;
}
.cell-container .nav-button {
  width: 3vw !important;
  padding-right: 0.2vw;
}

/* Game Cell */
.blocklyWidgetDiv {
  z-index: 1000 !important;
}
#notebook-game-view * {
  font-family: Rajdhani;
}
#notebook-game-view .show-notebook-game-solution-btn {
  position: relative !important;
  display: flex;
  width: 50vw;
  font-size: 1.5vw;
  justify-content: flex-end;
  margin-left: auto !important;
}
.notebook-game-content-wrapper {
  display: flex;
  justify-content: center;
}
.notebook-game-content-wrapper,
.notebook-game-content-wrapper * {
  font-family: sans-serif !important;
}
.notebook-game-action-buttons {
  display: flex;
  width: 30vw;
  align-self: flex-start;
  margin-left: calc((100% - 30vw - 47vw - 8vw) / 2);
  justify-content: space-between;
  font-size: 1.5vw;
}
.notebook-game-action-buttons * {
  font-size: 1.5vw;
}
.game-cell .notebook-game-instructions-box {
  background-color: #ff9a9b;
  border-radius: 1vw;
  margin: auto;
  margin-bottom: 1vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 4.5vw;
}
.notebook-game-instructions-box img {
  height: 90%;
  margin-left: 1vw;
}
#notebook-game-instructions {
  font-family: Andika;
  flex: 1 1 auto;
  font-size: 1.5vw;
  margin-left: 20px;
  margin-right: 5px;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.7vw;
  padding: 1.5vh 0;
}

/* Puzzle Cell */
.cell-container #puzzle-view {
  padding: 0 !important;
}
.cell-container .puzzle-title {
  font-family: "rajdhani-semibold" !important;
}
.cell-container .text-puzzle-option,
.cell-container .puzzle-explanation-text {
  font-family: "rajdhani-medium" !important;
  font-size: 1.6vw !important;
}
.cell-container .puzzle-explanation {
  font-family: "rajdhani-semibold" !important;
}
.correct-option {
  border: 1px solid #aecb2a !important;
  background-color: #aecb2a !important;
}
.wrong-option {
  border: 1px solid #fe7d00 !important;
  background-color: #fe7d00 !important;
}
.image-wrong-option {
  background-color: #fe7d00 !important;
}
.cell-container .option-letter {
  border: none !important;
  font-size: 1.6vw !important;
  font-family: "rajdhani-medium" !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cell-container .puzzle-question-text {
  font-size: 1.6vw !important;
  font-family: "rajdhani-medium" !important;
  padding-bottom: 1vh;
}

/* Table Cell */
.cell-container .table-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cell-container .table-cell .table-title {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  padding-bottom: 1vw;
  font-size: 2.4vw !important;
  font-family: "rajdhani-semibold";
}
.cell-container .table-cell .table-content {
  max-height: 60vh;
  overflow: auto;
  max-width: 100%;
  font-size: 1.6vw !important;
  font-family: "rajdhani-medium";
}
.table-cell table {
  margin: 0;
  border-collapse: collapse;
}
.table-cell table th,
.table-cell table td {
  text-align: center;
  border: 1px solid #dddddd;
  padding: 10px;
}
.notebook-button-holder button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0.5vw 0 !important;
}

.notebook-button-holder .active {
  transform: scale(1.2) !important;
}

.notebook-button-holder .giveup-level {
  border: 0.2vw solid #b30a74 !important;
}

.notebook-button-holder > img {
  width: 1vw;
}
.notebook-side-nav svg.nav-button {
  width: 5vw;
}
.notebook-side-nav {
  width: 8vw;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2vh;
}
.notebook-side-nav #notes-id {
  position: relative !important;
}
.notebook-help-video-btn .watch-help-video {
  margin: 0 !important;
  background-color: #ff9a9b !important;
  padding: 0.2vw 0.5vw !important;
}
.notebook-help-video-btn .watch-help-video {
  font-family: "rajdhani-medium";
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  border: none;
  background-color: rgba(255, 255, 255, 0.4);
  margin-right: 1vw;
  border-radius: 0.5vw;
  padding: 0 0.5vw;
  font-size: 1.5vw;
  transition: 0.3s;
}
.notebook-help-video-btn .watch-help-video:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.notebook-help-video-btn .watch-help-video img {
  width: 2vw;
  margin: 3px;
}

@import "prismjs/themes/prism.css";
html {
  height: 100%;
}
@font-face {
  font-family: "Rajdhani";
  src: url("../fonts/rajdhani-medium.ttf");
}
@font-face {
  font-family: "Rajdhani";
  src: url("../fonts/rajdhani-bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "Andika";
  src: url("../fonts/Andika-Regular.ttf");
}
@font-face {
  font-family: "Andika";
  src: url("../fonts/Andika-Bold.ttf");
  font-weight: bold;
}
:root {
  --story-font: Andika;
  --game-instructions-font: Andika;
  --puzzle-font: Andika;
}
.student-module-page {
  font-family: Rajdhani !important;
  background-color: #e2e7ea !important;
}

.notebook-button {
  background-color: rgb(254, 125, 0);
  border: none;
  font-size: 1.5vw;
  margin-top: 0.3vw;
  padding: 5px 20px;
  transition: 0.5s;
  margin-left: 10px;
  height: 75%;
  border-radius: 50%;
}
.notebook-button:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
/* Story Css */
#story-view .invisible {
  visibility: hidden;
}
#storyunderConstruction {
  width: 85vw;
  display: flex;
  justify-content: center;
  margin-top: 40vh;
}
.python-story-area {
  display: flex;
  flex-direction: column;
  padding: 10px 35px;
  height: 86vh;
}
.story-area > img {
  width: 4vw;
  cursor: pointer;
}
.python-story-content {
  display: flex;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
}
.python-story-arrows {
  display: flex;
  align-items: center;
}
.python-story-arrows > img {
  width: 30px;
  height: 30px;
  background-color: #ffb017;
  padding: 5px;
  border-radius: 10px;
  margin: 5px;
  cursor: pointer;
}
.main-story-view {
  padding: 10px;
  height: 80vh;
  width: 80vw;
}
.python-story-text-container {
  width: 49%;
  height: 100%;
  overflow: auto;
}
.main-story-view > .row {
  /* justify-content: space-between; */
  max-width: 100%;
  margin: auto;
}
.story-title {
  font-weight: bold;
}
.story-title,
.story-page-number {
  margin: 0;
  font-size: 2vw;
  margin-bottom: 0.5vw;
  font-family: var(--story-font), "Ubuntu", sans-serif;
}
.story-completed-button {
  height: 3vw;
  border-radius: 10px;
  border: none;
  background-color: #ffb017;
  font-size: 1.5vw;
  padding: 5px 20px;
  transition: 0.5s;
  cursor: pointer;
  font-family: Rajdhani;
  margin: 5px;
}
.story-slide {
  margin-top: 0;
  margin-bottom: 1vw;
}
.story-text {
  margin: 0;
  font-size: 2vw;
  font-family: var(--story-font), "Ubuntu", sans-serif;
  padding-bottom: 2vw;
  text-wrap :wrap;
}
.feedback-main-box {
  font-family: Rajdhani;
  width: 20vw;
  /* height: 15vw; */
  border: 1px solid #00868d;
  background-color: rgba(255, 255, 255, 0.93);
  opacity: 0;
  border-radius: 2vw;
  position: fixed;
  z-index: 5;
  left: calc(50% - 7.5vw);
  top: 5vw;
  padding: 1vw 0px;
  transform: translate(0, -18vw);
  transition: 0.3s;
  z-index: 1000 !important;
}
.feedback-main-box.active {
  animation-name: popupFeedback;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.16, 1.38, 1, 1.38);
}
.feedback-main-box.inactive {
  animation-name: popupFeedbackReverse;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.57, -0.07, 0.51, 1.15);
}
@keyframes popupFeedback {
  0% {
    transform: translate(0, -18vw) rotateX(-90deg);
    opacity: 0;
  }
  70% {
    transform: translate(0, 2vw) rotateX(0deg);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes popupFeedbackReverse {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, -18vw);
    opacity: 0;
  }
}
.feedback-main-box h5 {
  font-size: 2vw;
  margin: 0;
  font-weight: 500;
}
.feedback-main-box div > img {
  margin-left: 0.4vw;
  width: 2.5vw;
  margin-bottom: 0.4vw;
}
.feedback-main-box hr {
  display: block;
  width: 90%;
  background-color: #707070;
  opacity: 0.2;
  margin: auto;
  margin-bottom: 1vw;
  margin-top: 0.3vw;
}
.feedback-main-box p {
  text-align: center;
  font-size: 1.7vw;
  color: #6a6a6a;
  line-height: 1.8vw;
  margin-bottom: 0.6vw;
  margin-top: 0;
}
.feedback-main-box .row {
  width: 100%;
  margin: 0;
}
.feedback-main-box .col > img {
  width: 2.5vw;
  transition: 0.5s;
  margin: 0;
  margin-bottom: 0.3vw;
}
.feedback-main-box .col > img:hover {
  cursor: pointer;
  transform: scale(1.15);
  transition: 0.5s;
}
.close-cross {
  position: absolute;
  top: 0.7vw;
  right: 0.7vw;
  width: 1.3vw;
  transition: 0.3s;
}
.close-cross:hover {
  cursor: pointer;
  transform: scale(1.15);
  transition: 0.3s;
}
.feedback-next-level {
  border-radius: 20px;
  border: none;
  background-color: #aecb2a;
  padding: 0.7vw;
  transition: 0.5s;
  font-size: 1.5vw;
  line-height: 1.3vw;
  width: 90%;
  cursor: pointer;
  /* position: absolute;
  bottom: 0.5vw; */
}
.feedback-next-level:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
/* Game Css */
.game-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Rajdhani;
}
#game-view *,
#game-assesment-view * {
  font-family: Rajdhani;
}
#game-view,
#game-assesment-view {
  padding-bottom: 1.5vw;
  height: inherit;
}
#game-view .linkgame-bottom-nav,
#assesment-view .linkgame-bottom-nav {
  width: 77vw;
  margin: 0.3vw auto;
}
#game-view > .bad-job-feedback {
  font-family: Rajdhani;
  width: 20vw;
  /* height: 12vw; */
  height: fit-content;
  border: 1px solid #00868d;
  background-color: rgba(255, 255, 255, 0.93);
  opacity: 0;
  border-radius: 2vw;
  position: fixed;
  z-index: 5;
  right: 2vw;
  bottom: 2vw;
  padding: 1vw;
  transform: translate(0, -14vw);
  transition: 0.3s;
}
.game-button-holder,
.assesment-button-holder,
.notebook-button-holder,
.puzzle-button-holder,
.python-button-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}
.game-button-holder button.active,
.assesment-button-holder button.active,
.notebook-button-holder button.active,
.puzzle-button-holder button.active,
.python-button-holder button.active {
  border: 0.2vw solid #00554b;
  transform: scale(1.3);
  z-index: 1;
}
.game-button-holder button.completed,
.assesment-button-holder button.completed,
.puzzle-button-holder button.completed{
  background-color: #25A596;
}
.game-button-holder button.ongoing,
.assesment-button-holder button.ongoing,
.puzzle-button-holder button.ongoing {
  background-color: #AECB2A;
}
.game-button-holder button.incomplete,
.assesment-button-holder button.incomplete,
.puzzle-button-holder button.incomplete {
  background-color: #EFEFEF; 
}
.assesment-button-holder button.giveup-level {
  border: 0.2vw solid #b30a74 !important;
}
.game-button-holder img,
.assesment-button-holder img,
.notebook-button-holder img {
  width: 0.5vw;
  height: 1px;
  filter: brightness(0.1);
}
.game-button-holder div,
.assesment-button-holder div,
.notebook-button-holder div {
  border-radius: 10px;
  display: flex;
  margin: 0;
  height: 2.5vw;
  margin: 0.4vw 0px;
  align-items: center;
  border: 0.2vw solid #25a596;
  padding: 3px 0px;
}

.game-button-holder button,
.puzzle-button-holder > button,
.assesment-button-holder button,
.python-button-holder button, 
.notebook-button-holder button {
  height: 2vw;
  width: 2vw;
  font-size: 1vw;
  border: 0.2vw solid #25a596;
  margin: 1vw 0;
  border-radius: 50%;
  transition: 0.5s;
  cursor: pointer;
  font-family: "rajdhani-medium" !important;
  position: relative;
  z-index: 1;
}
.puzzle-button-holder > button.active,
.python-button-holder > button.active {
  border: 0.2vw solid #00554b;
  transform: scale(1.3);
  z-index: 2;
}
.game-button-holder button:not(.active):hover,
.puzzle-button-holder > button:not(.active):hover,
.assesment-button-holder:not(.locked) button:not(.active):hover,
.python-button-holder > button:not(.active):hover,
.notebook-button-holder > button:not(.active):hover {
  /* transition: 0.3s; */
  transform: scale(1.1);
}
.game-button-holder button::before,
.puzzle-button-holder > button::before,
.assesment-button-holder button::before,
.python-button-holder button::before, 
.notebook-button-holder button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  pointer-events: none;
  z-index: -1;
  transition: transform 0.3s ease-out;
}
.game-button-holder button:not(.active):hover::before,
.puzzle-button-holder > button:not(.active):hover::before,
.assesment-button-holder button:not(.active):hover::before,
.python-button-holder button:not(.active):hover::before,
.notebook-button-holder button:not(.active):hover::before {
  transform: scale(1.1);
}
.game-button-holder button.active::before,
.puzzle-button-holder > button.active::before,
.assesment-button-holder button.active::before,
.python-button-holder button.active::before,
.notebook-button-holder button.active::before {
  transform: scale(1.3);
}
.puzzle-button-holder > img,
.python-button-holder > img {
  width: 1vw;
}
.instructions-box {
  background-color: #ff9a9b;
  border-radius: 1vw;
  width: calc(47vw + 30vw + 8vw);
  margin: auto;
  min-height: 4.5vw;
  margin-bottom: 1vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.demo-video-button {
  position: absolute;
  right: 13px;
  top: 10px;
}
.demo-video-button .watch-help-video {
  margin: 0 !important;
  background-color: #ff9a9b !important;
  padding: .2vw .5vw !important;
}
.demo-video-button:hover {
  transform: scale(1.1);
  transition: 0.3s;
  font-weight:inherit;
}
.instructions-box .watch-help-video, .demo-video-button .watch-help-video {
  font-family: 'rajdhani-medium';
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  border: none;
  background-color: rgba(255, 255, 255, 0.4);
  margin-right: 1vw;
  border-radius: 0.5vw;
  padding: 0 0.5vw;
  font-size: 1.5vw;
  transition: 0.3s;
}
.instructions-box .watch-help-video:hover {
  transform: scale(1.1);
  transition: 0.3s;
}
.instructions-box img {
  height: 90%;
  margin-left: 1vw;
}
#instructions,
#assesment-view #assesment-instructions {
  font-family: var(--game-instructions-font);
  flex: 1 1 auto;
  font-size: 1.5vw;
  margin-left: 20px;
  margin-right: 5px;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.7vw;
}
.game-content-wrapper {
  display: flex;
  justify-content: center;
}
.game-content-wrapper,
.game-content-wrapper * {
  font-family: sans-serif !important;
}
.action-buttons-playground {
  display: flex;
  width: 52vw;
  align-self: flex-start;
  margin-left: calc((100% - 20vw) / 2);
  /* margin-left: calc((100% - 30vw - 47vw - 8vw)/2); */
  justify-content: space-between;
  font-size: 1.5vw;
}
.action-buttons-playground * {
  font-size: 1.5vw;
}
#run-code-button,
#assesment-run-code-button {
  width: 20vw;
  border-radius: 10px;
  border: none;
  background-color: #aecb2a;
  padding: 5px 20px;
  transition: 0.5s;
}
#run-code-button:hover,
#assesment-run-code-button:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
.show-solution-btn {
  display: flex;
  width: 30vw;
  margin-left: 3vw;
  font-size: 1.5vw;
}
.show-game-solution-btn {
  position: absolute;
  right: 3vw;
  display: flex;
  width: 50vw;
  font-size: 1.5vw;
  justify-content: flex-end;
}
#run-restart-button,
#assesment-run-restart-button,
#show-solution,
#show-puzzle-solution,
#game-publish-button,
#game-delete-button,
#description-save-button,
#python-publish-button,
#python-delete-button {
  border-radius: 10px;
  border: none;
  background-color: #aecb2a;
  padding: 5px 20px;
  transition: 0.5s;
}
#run-restart-button:hover,
#assesment-run-restart-button:hover,
#show-solution:hover,
#show-puzzle-solution:hover,
#game-publish-button:hover,
#game-delete-button:hover,
#description-save-button:hover,
#python-publish-button:hover,
#python-delete-button:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
#game-restart-button,
#assesment-game-restart-button,
#assesment-next-level,
.reset-code {
  border-radius: 10px;
  border: none;
  background-color: #ffb017;
  padding: 5px 20px;
  transition: 0.5s;
}
#game-restart-button:hover,
#assesment-game-restart-button:hover,
#assesment-next-level:hover,
.reset-code:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
.action-buttons {
  display: flex;
  width: 30vw;
  align-self: flex-start;
  margin-left: calc((100% - 30vw - 47vw - 8vw) / 2);
  justify-content: space-between;
  font-size: 1.5vw;
}
.action-buttons * {
  font-size: 1.5vw;
}
.feedback-next-level,
.feedback-okay-button {
  border-radius: 20px;
  border: none;
  background-color: #aecb2a;
  padding: 0.7vw;
  transition: 0.5s;
  font-size: 1.5vw;
  line-height: 1.3vw;
  width: 90%;
  /* position: absolute;
  bottom: 0.5vw; */
}
.feedback-okay-button {
  background-color: #ffb017;
}
.feedback-next-level:hover,
.feedback-okay-button:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
button {
  cursor: pointer;
}
#game-up,
#game-down,
#game-left,
#game-right,
#assesment-up,
#assesment-down,
#assesment-left,
#assesment-right {
  display: flex;
  width: 2.5em;
  border: none;
  padding: 0;
  transition: 0.5s;
}
#game-up:hover,
#game-down:hover,
#game-left:hover,
#game-right:hover,
#assesment-up:hover,
#assesment-down:hover,
#assesment-left:hover,
#assesment-right:hover {
  transform: scale(1.1);
  transition: 0.3s;
}
.play-video-help {
  width: 90%;
}
#blockly {
  border: 1px solid rgba(50, 50, 50, 0.1);
}
#game-blocks-count,
#assesment-game-blocks-count {
  font-family: Rajdhani !important;
  background-color: #ff9a9b;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 1.5vw;
  padding: 5px 10px;
  border-radius: 0 0 10px 10px;
}
#game-blocks-count span,
#assesment-game-blocks-count span {
  font-family: Rajdhani !important;
}
.demo-level-btn {
  background-color: #ff9a9b !important;
  border: 0.2vw solid #b30a74 !important;
}
.demo-level-completed-btn {
  background-color: #ff9a9b !important;
  border: 0.2vw solid #00554b !important;
}
/* Puzzle Css */
#puzzle-container {
  padding-bottom: 2vw;
  height: 100%;
}
#puzzle-view,
#puzzle-assesment-view {
  padding: 0 3vw;
  height: 100%;
}
#puzzle-view *,
#puzzle-assesment-view * {
  font-family: Rajdhani;
}
#puzzle-view .puzzle-title,
#puzzle-assesment-view .puzzle-title {
  font-family: var(--puzzle-font);
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 2.4vw;
}
#puzzle-assesment-view {
  padding-bottom: 2vw;
}
#puzzle-assesment-view #puzzle-view {
  padding: 0vw !important;
}
#puzzle-view .explanation-box .puzzle-explanation-image {
  margin-left: auto;
}
#puzzle-view .puzzle-description,
#puzzle-view .puzzle-question-text,
#puzzle-view .puzzle-explanation-text,
#puzzle-assesment-view .puzzle-description,
#puzzle-assesment-view .puzzle-question-text,
#puzzle-assesment-view .puzzle-explanation-text {
  font-family: var(--puzzle-font);
  font-size: 1.7vw;
  margin-top: 0;
}
.puzzle-question-text,
.puzzle-explanation-text {
  margin-bottom: 0;
  text-wrap: wrap;
}
#puzzle-view .col-3,
#puzzle-view .col-9,
#puzzle-assesment-view .col-3,
#puzzle-assesment-view .col-9 {
  padding: 0;
}
#puzzle-view .row,
#puzzle-assesment-view .row {
  margin-left: 10px;
  margin-right: 10px;
}
.puzzle-question-picture {
  padding-left: 3vw;
  width: 18vw;
  height: 18vw;
  margin-top: 10px;
}
.puzzle-option-container {
  font-family: var(--puzzle-font);
  margin-top: 1vw;
  width: 100%;
  margin-bottom: 0.35vw;
}
.puzzle-option-container * {
  font-family: var(--puzzle-font) !important;
}
.puzzle-card > .puzzle-option > img {
  width: 13vw;
  height: 13vw;
}
.puzzle-option.not-clicked {
  cursor: pointer;
  transition: 0.3s;
}
.puzzle-option.not-clicked:hover {
  transform: scale(1.05);
  transition: 0.3s;
}
.puzzle-option-text.not-clicked {
  cursor: pointer;
  transition: 0.3s;
  transform: scale(1);
  transition-property: none;
  transition-property: transform;
}
.puzzle-option-text.not-clicked:hover {
  transform: scale(1.01);
  transition-property: none;
  transition-property: transform;
  transition: 0.3s;
}
.puzzle-option {
  border: 1px solid #cccccc;
  border-bottom: none;
  z-index: 5;
  position: relative;
  margin: 0;
  padding: 0;
}
.puzzle-option.focussed {
  border: 0.25vw solid #25a596 !important;
}
.puzzle-option-text {
  font-family: var(--puzzle-font) !important;
  z-index: 5;
  position: relative;
  margin: 0.35vw 0;
  padding: 0;
  width: 100%;
  height: 3.5vw;
  font-size: 2vw;
  display: flex;
  padding-left: 1vw;
  align-items: center;
}
.puzzle-option-text.focussed {
  border: 0.2vw solid #25a596 !important;
}
.puzzle-option-text.notebook-puzzle-option {
  border: 1px solid #cccccc;
}
.text-puzzle-option {
  background-color: white;
}
.puzzle-option > .option-letter {
  background-color: #cccccc;
  text-align: center;
  width: 100%;
  height: 3vw;
  font-size: 2vw;
}
#puzzle-view .puzzle-explanation,
#puzzle-assesment-view .puzzle-explanation {
  font-family: var(--puzzle-font);
  font-weight: bold;
  font-size: 2.2vw;
  margin-bottom: 0;
  margin-top: 0.5vw;
}
.puzzle-submit-button {
  border-radius: 10px;
  border: none;
  background-color: #ffb017;
  padding: 5px 20px;
  transition: 0.5s;
  font-size: 1.5vw;
  margin-top: 0.3vw;
  width: 20vw;
  /* flex:1; */
}
.puzzle-submit-button:not(:disabled):hover {
  transform: scale(1.05);
  transition: 0.5s;
}
/* Assesment Css */
#assesment-view #assesment-next-level {
  background-color: #aecb2a;
}
#assesment-view .assesment-start-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
}
#assesment-view .assesment-start-screen * {
  font-family: Rajdhani;
}
#assesment-view #assesment-start-button {
  border-radius: 2vw;
  width: 40vw;
  height: 15vw;
  background-color: #aecb2a;
  border: none;
  font-size: 10vw;
  line-height: 10vw;
  font-weight: bold;
  transition: 0.5s;
}
#assesment-view #assesment-start-button:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
#assesment-view .assesment-start-screen > p {
  font-size: 1.8vw;
  width: 45vw;
  text-align: center;
}
#assesment-view-button {
  position: absolute;
  top: 5vw;
  right: 2vw;
  border-radius: 10px;
  border: none;
  background-color: #ffb017;
  padding: 5px 20px;
  transition: 0.5s;
  font-size: 1.5vw;
}
#assesment-view-button:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
.assesment-viewonly-prev,
.assesment-viewonly-next {
  font-size: 1vw;
}
.assesment-viewonly-prev:hover,
.assesment-viewonly-next:hover {
  cursor: pointer;
}
#assesment-view > .bad-job-feedback-static {
  font-family: Rajdhani;
  width: 20vw;
  height: 10.5vw;
  border: 1px solid #00868d;
  background-color: rgba(255, 255, 255, 0.93);
  opacity: 0;
  border-radius: 2vw;
  position: fixed;
  z-index: 5;
  right: 2vw;
  bottom: 2vw;
  padding: 1vw;
  transform: translate(0, -14vw);
  transition: 0.3s;
}
#assesment-view .assesment-viewonly-prev,
#assesment-view .assesment-viewonly-next {
  font-size: 2vw;
}
.test-screen {
  margin-bottom: 0;
}
.assesment-button-holder.locked > button {
  font-family: Rajdhani;
  cursor: default;
  opacity: 0.7;
}
#assesment-next-level {
  font-size: 1.5vw;
  height: 3.2vw;
  margin-bottom: 0.6vw;
}
.assesment-status-indicator-holder {
  width: 3vw;
  height: 3vw;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.assesment-status-indicator {
  width: 3vw;
  margin: 0;
  padding: 0;
  margin-left: 0.1vw;
}
#gameunderConstruction,
#puzzleunderConstruction,
#storyunderConstruction,
#assesmentunderConstruction {
  width: 85vw;
  display: flex;
  justify-content: center;
  margin-top: 40vh;
}
#gameunderConstruction h1,
#puzzleunderConstruction h1,
#storyunderConstruction h1,
#assesmentunderConstruction h1 {
  font-weight: 500;
  color: gray;
}
.giveup-button {
  border-radius: 10px;
  border: none;
  background-color: #fe7d00;
  padding: 5px 20px;
  transition: 0.5s;
  font-size: 1.5vw;
}
.giveup-button:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
.assesment-finish-screen {
  padding: 0 5vw;
  padding-top: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.assesment-finish-screen * {
  font-family: Rajdhani;
}
.assesment-finish-screen h2 {
  font-size: 4vw;
  margin-bottom: 0;
  font-weight: bold;
  margin: 0;
}
.assesment-finish-screen h3 {
  font-size: 3vw;
  margin: 0;
  margin-bottom: 0.5rem;
  font-weight: normal;
}
.assesment-finish-screen .table {
  font-size: 2vw;
  margin: 0 1vw;
  width: 100%;
  text-align: center;
}
#assesment-view-button {
  position: absolute;
  top: 5vw;
  right: 2vw;
  border-radius: 10px;
  border: none;
  background-color: #ffb017;
  padding: 7px 20px;
  transition: 0.5s;
  font-size: 1.5vw;
}
#assesment-view-button:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
.assesment-finish-table-holder {
  margin: 2vw 0;
  display: flex;
  justify-content: space-around;
}
.assesment-finish-table-cell {
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.assesment-finish-table-cell .colour-red {
  color: #f69940;
}
.assesment-finish-table-cell .colour-green {
  color: #72b7ba;
}
table.table-bordered {
  border: 1px solid rgba(90, 90, 90, 0.2);
}
table.table-bordered > thead > tr > th {
  border: 1px solid rgba(90, 90, 90, 0.2);
}
table.table-bordered > tbody > tr > td {
  border: 1px solid rgba(90, 90, 90, 0.2);
}
#notes-id {
  margin: 10px;
  padding: 5px 20px;
  position: absolute;
  bottom: 0;
  background-color: #ffb017;
  border: none;
  border-radius: 10px;
  font-size: 1.5vw;
  font-family: Rajdhani;
}
#notes-id:hover {
  transform: scale(1.05);
  transition: 0.5s;
}

/* Python Editor Style */
#python-view, #assessment-python-view {
  padding-left: 0;
  padding-right: 0;
  background-color: white;
}

#python-view {
  height: 90vh;
}
#assessment-python-view {
  height: calc(90vh - 3vw) !important;
}

#python-view > div.MuiGrid-root.MuiGrid-container.css-1d3bbye {
  height: calc(100% - 3vw);
}
/* (When level buttons are moved up, remove the subtraction of 42.26px) */

#python-view
  > div.MuiGrid-root.MuiGrid-container.css-1d3bbye
  > div:nth-child(1)
  > div {
  margin: 0;
  padding: 2em;
  padding-top: 0;
  font-size: 1.2em;
  border-right: 1px solid lightgray;
}
.exercise-python-container {
  background-color: white;
}
#python-instruction {
  background-color: white;
  overflow-y: auto;
  box-sizing: border-box;
  border-right: 1px solid #d3d3d3;
  font-size: 1.2em;
  margin: 0;
  padding: 0 2em 2em;
  position: relative;
}
.python-drawer-box {
  align-items: center;
  display: flex;
  height: inherit;
  justify-content: center;
  position: absolute;
  z-index: 1000;
}
.python-drawer-box svg {
  border-top-right-radius: 1.5vw;
  border-bottom-right-radius: 1.5vw;
  background-color: #e2e7eae6;
  font-size: 2vw;
  height: 4vw;
  color: #00868D;
}
.python-drawer-box svg:hover {
  cursor: pointer;
  padding-left: 1vw;
  font-size: 3vw;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
}
#python-instruction .python-instruction-title-container {
  line-height: 2.8vw;
  margin: 5px;
  padding:5px 30px 5px 25px;
  display: flex;
  justify-content: center;
}
#assesment-view #python-instruction .python-instruction-title-container {
  padding:5px 25px !important;
}

#python-instruction #python-instruction-title {
  margin: auto;
  font-size: 2.8vw !important;
}

#python-instruction code {
  font-size: 0.9em;
  padding: 0.5em;
}
#python-instruction img {
  width: 100%;
}

.content-tab {
  height: calc(90vh - (3 * 1.6vw + 2 * 20px));
  flex: 1;
}
.editor-container {
  height: calc(70% - 1px);
  margin: 0;
  position: relative;
  border-top: 1px solid #d3d3d3;
}

.graphic-console {
  /* width: 96%; */
  height: calc(100% - 400px);
  /* margin-left: 10px; */
  flex: 1;
  width: 400px;
}
.editor {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  font-size: 18px;
}

#info {
  max-width: 800px;
  border: 1px dotted black;
}
#output-console {
  display: flex;
}
/* #canvas {
  width: 100%;
  height: 20rem;
  border: 2px solid black;
} */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
}

.clear-icon {
  cursor: pointer;
}

#output {
  margin: 0;
  /* margin-top: -18px; */
  /* vertical-align: text-top; */
  background-color: white;
  color: green;
  font-weight: bolder;
  height: 86%;
  width: 100%;
  overflow-y: auto;
  border: 0.5px solid #ccc;
}

#python-editor button {
  margin: 20px;
  padding: 10px;
}

#container {
  display: flex;
  justify-content: space-between;
}

#container * {
  font-family: Rajdhani !important;
}

#container button {
  font-family: Rajdhani !important;
  font-size: 1.5vw;
}

#container button:disabled {
  background-color: #d3d3d3 !important;
  cursor: default;
}

#input {
  margin-left: 10px;
  height: 11px;
  font-size: 10px;
  resize: none;
  overflow: hidden;
  background: transparent;
  color: #fff;
}
#status-info {
  color: darkred;
  margin: 20px 20px;
  height: 10px;
  font-weight: bolder;
}
.error,
.not-implemented {
  color: red;
}
.btn-enabled {
  color: black;
  background-color: #c0c0c0;
}

.btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: #c0c0c0;
  background-color: #ffffff;
}

.sketch-holder {
  border: 1px solid black;
  height: 400px;
  width: 400px;
  margin: 0;
  position: relative;
  overflow: auto;
}
.sketch-holder canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.python-graphics-grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  height: 400px;
  background-image:
    linear-gradient(to right, rgba(50, 50, 50, 0.3) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(50, 50, 50, 0.3) 1px, transparent 1px);
  background-size: 50px 50px;
  z-index: 10;
  pointer-events: none;
}

.python-graphics-grid-x-axis span, 
.python-graphics-grid-y-axis span {
  position: absolute;
  line-height: 12px;
  font-size: 12px;
}

.grid-controls {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 20;
}

.grid-toggle-btn, #help-icon {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  align-self: end;
  display: flex;
  padding-bottom: 5px;
}

.grid-toggle-btn img {
  height: 20px;
  width: 20px;
}

#help-icon img{
  margin-left: 10px;
  height: 22px;
  width: 22px;
}

.grid-slider {
  background: rgba(150, 150, 150, 0.51);
  border-radius: 4px;
  padding: 5px;
  margin-top: 5px;
  display: flex;
  color: white;
}

.grid-opacity-slider {
  width: 50px;
  margin-left: 10px;
}

.grid-slider.active {
  display: block;
}

/* Python Console */
.console-container {
  height: 30%;
  width: 100%;
  background-color: #f0f0f0;
}
#console-banner {
  font-family: Inconsolata, Menlo, Consolas, monospace;
  background-color: #dddd;
  margin: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 27px;
  font-size: 0.875em;
  padding: 0 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#console-banner p {
  margin: 0;
  padding-top: 4px;
  opacity: 0.6;
}
#clear-console-btn {
  width: 20px;
  cursor: pointer;
  opacity: 0.7;
}
.console-box {
  height: calc(100% - 27px);
  background-color: white;
  /* border-bottom-left-radius: 6px; */
  /* border-bottom-right-radius: 6px; */
  border-left: 0.5px solid #dddd;
  border-right: 0.5px solid #dddd;
  border-bottom: 0.5px solid #dddd;
  padding-bottom: 10px;
  box-sizing: border-box;
}
.console {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  height: 80%;
  font-family: Inconsolata, Menlo, Consolas, monospace;
  font-size: 1em;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  padding: 5px 14px;
  box-sizing: border-box;
}
.console .error-message {
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  background-color: #fec9c9;
  border: 1px solid rgb(254, 168, 168);
  border-radius: 6px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.console .error-message p {
  margin: 0;
  font-size: 1em;
}
.console .error-message p.error-header {
  font-weight: bold;
}
.console .console-text {
  margin: 0 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1em;
}
.console code {
  margin: 0 10px;
  background-color: hsla(0, 0%, 100%, 0.5);
  font-size: 1em;
}
.input-container {
  height: 20%;
  max-height: 5vh;
  min-height: 3vh;
  position: relative;
  display: flex;
  align-items: center;
  border: #b5b5b5;
}
#console-input-box {
  width: calc(100% - 10px);
  margin: 0px 10px;
  height: 100%;
  font-size: 1em;
  border: 1px solid grey;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 47px;
}
#console-input-box:focus {
  outline: none;
  border: 1px solid #25a596;
  box-shadow: none;
}
#send-input-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #aecb2a;
  border: none;
  border-radius: 5px;
  margin: 0;
  height: calc(100% - 6px);
  width: 26px;
  right: 14px;
}
.send-input-btn-icon {
  height: 60% !important;
  width: 90% !important;
}

.send-input-btn-icon:disabled {
  cursor: default;
}
/* test cases box */
.test-case-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 7px;
}

.test-case-failed-content {
  border: 1px solid rgb(255, 176, 23);
  border-top: none;
  padding: 10px;
  margin-top: -15px;
  margin-bottom: 10px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.graphic-window-panel {
  display: flex;
  flex-direction: column !important;
  padding-right: 2px;
  background-color: white;
}
.console::before {
  content: "This is the python console. Output will appear here.";
  color: #b1afaf;
}
.console:not(:empty)::before {
  display: none;
}
.reset-code {
  margin-left: 8px;
}
#python-solution-button {
  margin: 0px 8px 0px 16px;
}

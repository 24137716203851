@font-face {
  font-family: "rajdhani-regular";
  src: url("../fonts/rajdhani-regular.ttf");
}
@font-face {
  font-family: "rajdhani-semibold";
  src: url("../fonts/rajdhani-semibold.ttf");
}
@font-face {
  font-family: "rajdhani-bold";
  src: url("../fonts/rajdhani-bold.ttf");
}
@font-face {
  font-family: "rajdhani-light";
  src: url("../fonts/rajdhani-light.ttf");
}
@font-face {
  font-family: "rajdhani-medium";
  src: url("../fonts/rajdhani-medium.ttf");
}
.classes-container {
  padding: 0 5vw;
  padding-bottom: 1vw;
  background-color: #e2e7ea;
  line-height: 1.2;
  font-family: "rajdhani-medium";
}
.classes-container h1 {
  font-family: "rajdhani-bold";
  font-weight: bold;
  font-size: 4vw;
  margin-top: 12vh;
  margin-bottom: 0.5rem;
}
.classes-container h2 {
  font-family: "rajdhani-bold";
  font-weight: bold;
  font-size: 2.5vw;
  margin: 0;
}
.class-box {
  height: 4vw;
  background-color: #aecb2a;
  border-radius: 5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2vw;
  transition: 0.5s;
  margin-bottom: 1vw;
}
.class-box:hover {
  transition: 0.5s;
  transform: scale(1.01);
  cursor: pointer;
}
.class-box p {
  margin: 0;
  font-size: 1.5vw;
  justify-content: center;
  align-items: center;
}
.class-box img {
  margin-right: 1vw;
  width: 1.5vw;
}
.classes-selection .assignment-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.classes-selection .assignment-box .icon {
  margin: 0;
  width: 2vw;
}
.classes-selection .assignment-box .bar {
  margin: 0;
  width: 1vw;
}
.class-name-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 1vw 0.3vw 0vw;
}
.class-name-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-analytics {
  display: flex;
  align-items: center;
}

.view-analytics,
.view-community {
  border-radius: 10px;
  border: none;
  background-color: #ffb017;
  padding: 5px 20px;
  transition: 0.5s;
  font-size: 1.5vw;
  margin-left: 10px;
  font-family: "rajdhani-medium";
}
.view-analytics:hover,
.view-community:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
.analytics-icon {
  height: 1.5vw;
  width: 1.5vw;
  vertical-align: middle;
  margin-right: 0.2vw;
}
.classes-container > hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.download-class-report-btn {
  border-radius: 20%;
  border: 1px solid #00868d;
  background-color: transparent;
  color: #00868d;
  margin-left: 10px;
  font-family: "rajdhani-medium";
  display: flex;
  padding: 2.5px 5px;
  margin-bottom: 0.1vw;
}
.download-class-report-btn:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
.download-class-report-btn svg {
  vertical-align: middle !important;
  height: 1.3vw !important;
  width: 1.3vw !important;
}
.download-report-cards-btn {
  border-radius: 10px;
  border: 1px solid #00868d;
  background-color: transparent;
  padding: 3px;
  transition: 0.5s;
  font-size: 1.5vw;
  margin-left: 10px;
  font-family: "rajdhani-medium";
  color: #00868d;
  margin-right: 1vw;
}
.download-report-cards-btn:disabled {
  color: rgba(16, 16, 16, 0.3);
}
.download-report-cards-btn svg {
  vertical-align: middle !important;
  height: 1.5vw !important;
  width: 1.3vw !important;
  margin-left: 2px;
}
